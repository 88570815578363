var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.requestCreate.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"name","label-cols-sm":"2","label-cols-lg":"2","label":_vm.$t('label.name'),"label-for":"name"}},[_c('b-form-input',{class:{
    'is-invalid': _vm.submitted &&
      !_vm.$v.createForm.name.required
  },attrs:{"for":"name"},model:{value:(_vm.createForm.name),callback:function ($$v) {_vm.$set(_vm.createForm, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"createForm.name"}}),(_vm.submitted &&
    !_vm.$v.createForm.name.required)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.createForm.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t("message.requiredX", [_vm.$t("label.name")])))]):_vm._e()]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"email","label-cols-sm":"2","label-cols-lg":"2","label":_vm.$t('label.email'),"label-for":"email"}},[_c('b-form-input',{class:{
    'is-invalid':
      _vm.submitted &&
      (!_vm.$v.createForm.email.required ||
        !_vm.$v.createForm.email.email),
  },attrs:{"id":"email"},model:{value:(_vm.createForm.email),callback:function ($$v) {_vm.$set(_vm.createForm, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"createForm.email"}}),(_vm.submitted &&
    (!_vm.$v.createForm.email.required ||
      !_vm.$v.createForm.email.email)
    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.createForm.email.required)?_c('span',[_vm._v(_vm._s(_vm.$t("message.requiredX", [_vm.$t("label.email")])))]):_vm._e(),(!_vm.$v.createForm.email.email)?_c('span',[_vm._v(_vm._s(_vm.$t("message.validX", [_vm.$t("label.email")])))]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"mt-4 text-center",staticStyle:{"float":"right"}},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",staticStyle:{"margin-right":"10px"},attrs:{"type":"submit","disabled":_vm.status.processiong}},[_vm._v(" "+_vm._s(_vm.$t("label.createX", [_vm.$t("module.user")]))+" ")])])],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }