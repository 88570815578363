<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { ddlHelper, sessionHelper } from "@/utils";
import { SESSION } from "@/constants";
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import i18n from "@/i18n";
import { LOOKUP_MODULE } from '@/state/modules/lookup'
import { USER_MODULE } from '@/state/modules/user'
import { lookupMethods, userMethods } from "@/state/helpers";

export default {
  page: {
    title: i18n.t("label.createFormX", [i18n.t("module.user")]),
  },
  components: { Layout, PageHeader },
  beforeRouteLeave(to, from, next) {
    if (to.name != "User-listing") {
      sessionHelper.clearSearch(SESSION.USER_SEARCH);
    }
    next();
  },
  computed: {
    ...mapState(USER_MODULE, ["status"]),
    ...mapState(LOOKUP_MODULE),
    roleDdl() {
      return this.$store.state.lookup.roleDdl;
    },
  },
  data() {
    return {
      title: i18n.t("label.createX", [i18n.t("module.user")]),
      items: [
        {
          text: i18n.t("module.dashboard"),
          href: "/",
        },
        {
          text: i18n.t("label.moduleX", [i18n.t("module.user")]),
          href: "/user-index",
        },
        {
          text: i18n.t("module.user"),
          href: "/user",
        },
        {
          text: i18n.t("label.createX", [i18n.t("module.user")]),
          active: true,
        },
      ],
      submitted: false,
      statusDdl: ddlHelper.getActivationStatus(false, true),
      createForm: {
        name: "",
        email: "",
      },
    };
  },
  validations: {
    createForm: {
      name: { required },
      email: { required, email },
    },
  },
  created() {
    // this.getRoleDdl();
    this.lookupRole({ hasOptionAll: this.FALSE });
  },
  methods: {
    ...userMethods,
    ...lookupMethods,
    // getRoleDdl() {
    //   this.$store.dispatch(`lookup/getRoleLookup`, { hasOptionAll: false });
    // },
    requestCreate() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          name: this.createForm.name,
          email: this.createForm.email,
          roleId: this.createForm.roleId,
        };
        this.createUser({ data });
        // this.$store.dispatch(`user/createUser`, { data });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="requestCreate">
              <b-form-group id="name" label-cols-sm="2" label-cols-lg="2" :label="$t('label.name')" label-for="name">
                <b-form-input for="name" v-model.trim="createForm.name" :class="{
      'is-invalid': submitted &&
        !$v.createForm.name.required
    }"></b-form-input>
                <div v-if="submitted &&
      !$v.createForm.name.required" class="invalid-feedback">
                  <span v-if="!$v.createForm.name.required">{{
      $t("message.requiredX", [$t("label.name")])
    }}</span>
                </div>
              </b-form-group>

              <b-form-group id="email" label-cols-sm="2" label-cols-lg="2" :label="$t('label.email')" label-for="email">
                <b-form-input id="email" v-model.trim="createForm.email" :class="{
      'is-invalid':
        submitted &&
        (!$v.createForm.email.required ||
          !$v.createForm.email.email),
    }"></b-form-input>
                <div v-if="submitted &&
      (!$v.createForm.email.required ||
        !$v.createForm.email.email)
      " class="invalid-feedback">
                  <span v-if="!$v.createForm.email.required">{{
      $t("message.requiredX", [$t("label.email")])
    }}</span>
                  <span v-if="!$v.createForm.email.email">{{
      $t("message.validX", [$t("label.email")])
    }}</span>
                </div>
              </b-form-group>



              <div class="mt-4 text-center" style="float: right">
                <button class="btn btn-primary w-md waves-effect waves-light" style="margin-right: 10px" type="submit"
                  :disabled="status.processiong">
                  {{ $t("label.createX", [$t("module.user")]) }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>